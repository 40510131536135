import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    CategoryProductListContainer as SourceCategoryProductListContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceComponent/CategoryProductList/CategoryProductList.container';
import { FilterInputType } from 'Type/Category.type';
import { LayoutType } from 'Type/Layout.type';
import { PagesType } from 'Type/ProductList.type';

export const ProductListDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductList/ProductList.dispatcher'
);

/** @namespace Theme/Component/CategoryProductList/Container/mapStateToProps */
export const mapStateToProps = sourceMapStateToProps;

/** @namespace Theme/Component/CategoryProductList/Container/mapDispatchToProps */
export const mapDispatchToProps = sourceMapDispatchToProps;

/** @namespace Theme/Component/CategoryProductList/Container */
export class CategoryProductListContainer extends SourceCategoryProductListContainer {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        isMatchingListFilter: PropTypes.bool,
        isMatchingInfoFilter: PropTypes.bool,
        layout: LayoutType,
        filter: FilterInputType,
        requestProductList: PropTypes.func.isRequired,
        isCurrentCategoryLoaded: PropTypes.bool,
        totalItems: PropTypes.number.isRequired,
        totalPages: PropTypes.number,
        search: PropTypes.string,
        sort: PropTypes.objectOf(PropTypes.string),
        selectedFilters: PropTypes.objectOf(PropTypes.shape),
        pages: PagesType.isRequired,
        isPageLoading: PropTypes.bool,
        pageSize: PropTypes.number,
    };

    static defaultProps = {
        isMatchingListFilter: false,
        isMatchingInfoFilter: false,
        isCurrentCategoryLoaded: false,
        filter: {},
        layout: 'grid',
        totalPages: 1,
        search: '',
        sort: undefined,
        selectedFilters: {},
        isPageLoading: false,
        pageSize: 24,
    };

    containerProps() {
        const {
            filter,
            isPageLoading,
            pages,
            search,
            selectedFilters,
            sort,
            totalItems,
            totalPages,
            pageSize,
        } = this.props;

        return {
            filter,
            isPageLoading,
            pages,
            search,
            selectedFilters,
            sort,
            totalItems,
            totalPages,
            pageSize,
            isLoading: this.getIsLoading(),
            isPreventRequest: this.getIsPreventRequest(),
            mix: { block: 'CategoryProductList', mods: { layout: this.getLayout() } },
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryProductListContainer);
