import ProductCard from 'Component/ProductCard';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import {
    ProductListPageComponent as SourceProductListPageComponent,
} from 'SourceComponent/ProductListPage/ProductListPage.component';
import { DEFAULT_PLACEHOLDER_COUNT } from 'SourceComponent/ProductListPage/ProductListPage.config';
import { PRODUCTS_PRELOAD_COUNT } from 'Util/Product';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';
import { AfterPriority } from 'Util/Request/LowPriorityRender';

/** @namespace Theme/Component/ProductListPage/Component */
export class ProductListPageComponent extends SourceProductListPageComponent {
    renderPageItems() {
        const {
            items,
            selectedFilters,
            mix: {
                mods: {
                    layout = CategoryPageLayout.GRID,
                } = {},
            },
        } = this.props;

        return items.map((product, i) => {
            if (i < PRODUCTS_PRELOAD_COUNT) {
                return (
                    <ProductCard
                      product={ product }
                    // eslint-disable-next-line react/no-array-index-key
                      key={ i }
                      selectedFilters={ selectedFilters }
                      layout={ layout }
                      { ...this.containerProps() }
                      onLoad={ setLoadedFlag }
                      imageLoad="eager"
                    />
                );
            }

            return (
                // @ts-ignore
                <AfterPriority fallback={ <div style={ { minHeight: 200 } } /> }>
                    <ProductCard
                      product={ product }
                    // eslint-disable-next-line react/no-array-index-key
                      key={ i }
                      selectedFilters={ selectedFilters }
                      layout={ layout }
                      { ...this.containerProps() }
                      onLoad={ setLoadedFlag }
                      imageLoad="lazy"
                    />
                </AfterPriority>
            );
        });
    }

    renderPlaceholders() {
        const {
            numberOfPlaceholders = DEFAULT_PLACEHOLDER_COUNT,
            mix: {
                mods: {
                    layout = CategoryPageLayout.GRID,
                } = {},
            },
        } = this.props;

        return Array.from(
            { length: numberOfPlaceholders },
            (_, i) => {
                if (i < PRODUCTS_PRELOAD_COUNT) {
                    return (
                        <ProductCard
                          key={ i }
                          product={ {} }
                          layout={ layout }
                          onLoad={ setLoadedFlag }
                          imageLoad="eager"
                        />
                    );
                }

                return (
                    // @ts-ignore
                    <AfterPriority fallback={ <div style={ { minHeight: 200 } } /> }>
                        <ProductCard
                          key={ i }
                          product={ {} }
                          layout={ layout }
                          onLoad={ setLoadedFlag }
                          imageLoad="lazy"
                        />
                    </AfterPriority>
                );
            },
        );
    }
}

export default ProductListPageComponent;
