/* eslint-disable react/forbid-prop-types */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';

import './CategoryProductPerPage.style';

/** @namespace Theme/Component/CategoryProductPerPage/Component */
export class CategoryProductPerPageComponent extends PureComponent {
    static propTypes = {
        defaultListProductCount: PropTypes.number.isRequired,
        defaultGridProductCount: PropTypes.number.isRequired,
        gridCountOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
        listCountOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
        plpType: PropTypes.string.isRequired,
        handleChange: PropTypes.func.isRequired,
    };

    render() {
        const {
            defaultListProductCount,
            defaultGridProductCount,
            gridCountOptions,
            handleChange,
            listCountOptions,
            plpType,
        } = this.props;

        return (
            <div block="CategoryProductPerPage">
              <Field
                type={ FieldType.SELECT }
                attr={ {
                    id: 'CategoryProductPerPage',
                    name: 'CategoryProductPerPage',
                    defaultValue: plpType === 'grid' ? defaultGridProductCount : defaultListProductCount,
                    noPlaceholder: true,
                } }
                events={ {
                    onChange: handleChange,
                } }
                options={ plpType === 'grid' ? gridCountOptions : listCountOptions }
              />
            </div>
        );
    }
}

export default CategoryProductPerPageComponent;
