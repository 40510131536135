import TextPlaceholder from 'Component/TextPlaceholder';
import {
    CategoryDetailsComponent as SourceCategoryDetails,
} from 'SourceComponent/CategoryDetails/CategoryDetails.component';
import { ___ } from 'Util/Format';

import './CategoryDetails.style';

/** @namespace Theme/Component/CategoryDetails/Component */
export class CategoryDetailsComponent extends SourceCategoryDetails {
    renderCategoryText() {
        const {
            category: { name },
            isCurrentCategoryLoaded,
        } = this.props;

        if (isCurrentCategoryLoaded) {
            return (
                <TextPlaceholder content={ ___(name) } />
            );
        }

        return (
            <TextPlaceholder />
        );
    }
}

export default CategoryDetailsComponent;
