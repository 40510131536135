import {
    CategoryQuery as SourceCategoryQuery,
} from 'SourceQuery/Category.query';

/** @namespace Theme/Query/Category/Query */
export class CategoryQuery extends SourceCategoryQuery {
    _getDefaultFields() {
        return [
            ...super._getDefaultFields(),
            'hide_from_seo',
            'show_login_prompt',
            'uid',
        ];
    }
}

export default new CategoryQuery();
