/* eslint-disable react/forbid-component-props */
/* eslint-disable react/jsx-no-bind */

import CategoryProductList from 'Component/CategoryProductList';
import CategoryProductPerPage from 'Component/CategoryProductPerPage';
import Link from 'Component/Link';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import {
    CategoryFilterOverlay as SourceCategoryFilterOverlay,
    CategoryPageComponent as SourceCategoryPage,
} from 'SourceRoute/CategoryPage/CategoryPage.component';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import { isCrawler, isSSR } from 'Util/Browser';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';
import sso from 'Util/SSO';

import './CategoryPage.style';

export const CategoryFilterOverlay = SourceCategoryFilterOverlay;

/** @namespace Theme/Route/CategoryPage/Component */
export class CategoryPageComponent extends SourceCategoryPage {
    static defaultProps = {
        ...SourceCategoryPage.defaultProps,
        layout: '',
        pageSize: 24,
    };

    state = {
        activeLayoutType: undefined,
    };

    renderItemsCount(_isVisibleOnMobile = false) {
        return null;
    }

    renderCategoryProductList() {
        const {
            filter,
            search,
            selectedSort,
            selectedFilters,
            isMatchingListFilter,
            isCurrentCategoryLoaded,
            isMatchingInfoFilter,
            pageSize,
        } = this.props;

        const { activeLayoutType } = this.state;

        if (!this.displayProducts()) {
            setLoadedFlag();

            return null;
        }

        return (
            <div
              block="CategoryPage"
              elem="ProductListWrapper"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                <CategoryProductList
                  filter={ filter }
                  search={ search }
                  sort={ selectedSort }
                  selectedFilters={ selectedFilters }
                  isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
                  isMatchingListFilter={ isMatchingListFilter }
                  isMatchingInfoFilter={ isMatchingInfoFilter }
                  layout={ activeLayoutType || CategoryPageLayout.GRID }
                  pageSize={ pageSize }
                />
            </div>
        );
    }

    renderProductPerPage() {
        const { layout, onPageSizeChange } = this.props;

        return (
            <CategoryProductPerPage
              plpType={ layout }
              onPageSizeChange={ onPageSizeChange }
            />
        );
    }

    renderLoginButton() {
        if (sso.isEnabled()) {
            const onLoginButtonClick = (e) => {
                e.preventDefault();
                e.stopPropagation();
                sso.gotoLogin({ target: 'category page login button' });
            };

            return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                  className="btn login-btn"
                  onClick={ onLoginButtonClick }
                >
                    { __('Log In') }
                </Link>
            );
        }

        return (
            <Link
              className="btn login-btn"
              to="/login"
            >
                { __('Log In') }
            </Link>
        );
    }

    renderLoginWarning() {
        const { category: { show_login_prompt } } = this.props;

        if (show_login_prompt && !isSignedIn()) {
            const registerLink = sso.isEnabled() ? sso.getRegisterUrl() : '/customer/account/create';

            return (
                <div
                  block="CategoryPage"
                  elem="LoginWarning"
                >
                    <div
                      block="CategoryPage"
                      elem="LoginContent"
                    >
                        <h4>
                            { __('For a complete list of Tools and Services, please login for more information.') }
                        </h4>
                    </div>
                    <div
                      block="CategoryPage"
                      elem="LoginBtnArea"
                    >
                        { this.renderLoginButton() }
                        <p>
                            { __('Not registered? ') }
                            <Link
                              className="register-link"
                              to={ registerLink }
                            >
                                { __('Register here.') }
                            </Link>
                        </p>

                    </div>
                </div>
            );
        }

        return null;
    }

    renderMiscellaneous() {
        const { totalItems, isMobile } = this.props;

        if (totalItems === 0 || !this.displayProducts()) {
            return <aside block="CategoryPage" elem="Miscellaneous" mods={ { noResults: true } } />;
        }

        if (isMobile) {
            return (
                <aside block="CategoryPage" elem="Miscellaneous" mods={ { sortDropBlock: true } }>
                    <div
                      block="CategoryPage"
                      elem="LayoutWrapper Control-Sort"
                      mods={ { isPrerendered: isSSR() || isCrawler() } }
                    >
                        { this.renderCategorySort() }
                    </div>
                    <div
                      block="CategoryPage"
                      elem="LayoutWrapper Control-Categories"
                    >
                        { this.renderFilterOverlay() }
                        { this.renderFilterButton() }
                    </div>
                </aside>
            );
        }

        return (
            <aside block="CategoryPage" elem="Miscellaneous">
                <div
                  block="CategoryPage"
                  elem="LayoutWrapper Control-Sort"
                  mods={ { isPrerendered: isSSR() || isCrawler() } }
                >
                    { this.renderCategorySort() }
                </div>
                <div
                  block="CategoryPage"
                  elem="LayoutWrapper Control-Categories"
                >
                    { this.renderFilterOverlay() }
                    { this.renderFilterButton() }
                </div>
                <div
                  block="CategoryPage"
                  elem="LayoutWrapper Control-PerPage"
                >
                    { this.renderProductPerPage() }
                </div>
            </aside>
        );
    }

    renderContent() {
        return (
            <>
                { this.renderCategoryDetails() }
                { this.renderCmsBlock() }
                { this.renderLoginWarning() }
                { this.renderMiscellaneous() }
                { this.renderCategoryProductList() }
            </>
        );
    }
}

export default CategoryPageComponent;
