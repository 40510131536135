import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    mapDispatchToProps as SourceMapDispatchToProps,
    mapStateToProps as SourceMapStateToProps,
    ProductListContainer as SourceProductListContainer,
} from 'SourceComponent/ProductList/ProductList.container';
import history from 'Util/History';

/** @namespace Theme/Component/ProductList/Container/mapStateToProps */
export const mapStateToProps = SourceMapStateToProps;

/** @namespace Theme/Component/ProductList/Container/mapDispatchToProps */
export const mapDispatchToProps = SourceMapDispatchToProps;

/** @namespace Theme/Component/ProductList/Container */
export class ProductListContainer extends SourceProductListContainer {
    componentDidUpdate(prevProps) {
        const {
            sort,
            search,
            filter,
            pages,
            pageSize,
        } = this.props;

        const {
            sort: prevSort,
            search: prevSearch,
            filter: prevFilter,
            location: prevLocation,
            pageSize: prevPageSize,
        } = prevProps;

        const { pagesCount } = this.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ pagesCount: pagesLength });
        }

        const prevPage = this._getPageFromUrl(prevLocation);
        const currentPage = this._getPageFromUrl();

        if (search !== prevSearch
            || currentPage !== prevPage
            || pageSize !== prevPageSize
            || JSON.stringify(sort) !== JSON.stringify(prevSort)
            || JSON.stringify(filter) !== JSON.stringify(prevFilter)
        ) {
            if (pageSize === prevPageSize) {
                this.requestPage(this._getPageFromUrl());
            } else {
                this.requestPage(1);
                setTimeout(() => {
                    history.push({ search: '' });
                });
            }
        }
    }

    requestPage(currentPage = 1, isNext = false) {
        const {
            sort,
            search,
            filter,
            pageSize,
            requestProductList,
            requestProductListInfo,
            noAttributes,
            noVariants,
            isWidget,
        } = this.props;

        /**
         * In case the wrong category was passed down to the product list,
         * prevent it from being requested.
         */
        if (filter.categoryIds === -1) {
            return;
        }

        /**
         * Do not request page if there are no filters
         */
        if (!search && !this.isEmptyFilter()) {
            return;
        }

        // TODO: product list requests filters alongside the page
        // TODO: sometimes product list is requested more then once
        // TODO: the product list should not request itself, when coming from PDP

        const options = {
            isNext,
            noAttributes,
            noVariants,
            args: {
                sort,
                filter,
                search,
                pageSize,
                currentPage,
            },
        };

        const infoOptions = {
            args: {
                filter,
                search,
            },
        };

        requestProductList(options);

        if (!isWidget) {
            requestProductListInfo(infoOptions);
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductListContainer));
