import { connect } from 'react-redux';

import {
    CategoryPageContainer as SourceCategoryPageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceRoute/CategoryPage/CategoryPage.container';
import CategoryReducer from 'Store/Category/Category.reducer';
import { withReducers } from 'Util/DynamicReducer';
import { debounce } from 'Util/Request/Debounce';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';
import { getQueryParam } from 'Util/Url';

import {
    LOADING_TIME,
    SortDirections,
} from './CategoryPage.config';

/** @namespace Theme/Route/CategoryPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    defaultListProductCount: state.ConfigReducer.list_per_page,
    defaultGridProductCount: state.ConfigReducer.grid_per_page,
    isSignedIn: state.MyAccountReducer.isSignedIn,
});

/** @namespace Theme/Route/CategoryPage/Container/mapDispatchToProps */
export const mapDispatchToProps = sourceMapDispatchToProps;

/** @namespace Theme/Route/CategoryPage/Container */
export class CategoryPageContainer extends SourceCategoryPageContainer {
    config = {
        sortKey: 'position',
        sortDirection: SortDirections.ASC,
    };

    static defaultProps = {
        ...SourceCategoryPageContainer.defaultProps,
        defaultListProductCount: 10,
        defaultGridProductCount: 12,
    };

    containerFunctions = {
        onSortChange: this.onSortChange.bind(this),
        onPageSizeChange: this.onPageSizeChange.bind(this),
        onGridButtonClick: this.onGridButtonClick.bind(this),
        onListButtonClick: this.onListButtonClick.bind(this),
    };

    componentDidMount() {
        const {
            categoryIds,
            category: {
                id,
            },
        } = this.props;

        window.scrollTo(0, 0);

        /**
         * Ensure transition PLP => homepage => PLP always having proper meta
         */
        this.updateMeta();

        /**
         * Always make sure the navigation show / hide mode (on scroll)
         * is activated when entering the category page.
         * */
        this.updateNavigationState();

        /**
         * Always update the history, ensure the history contains category
         */
        this.updateHistory();

        /**
         * Get default PLP type and type list
         */
        this.updatePlpTypes();

        /**
         * Make sure to update header state, if the category visited
         * was already loaded.
         */
        if (categoryIds === id) {
            this.updateBreadcrumbs();
            this.updateHeaderState();
        } else {
            /**
             * Still update header and breadcrumbs, but ignore
             * the category data, as it is outdated
             */
            this.updateHeaderState(true);
            this.updateBreadcrumbs(true);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            isOffline,
            categoryIds,
            category: {
                id,
            },
            currentArgs: {
                filter,
                currentPage,
            } = {},
            isSignedIn,
        } = this.props;

        const {
            breadcrumbsWereUpdated,
        } = this.state;

        const {
            categoryIds: prevCategoryIds,
            category: {
                id: prevId,
            },
            currentArgs: {
                filter: prevFilter,
                currentPage: prevPage,
            } = {},
            isSignedIn: prevIsSignedIn,
        } = prevProps;

        // TODO: category scrolls up when coming from PDP

        if (isOffline) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        /**
         * If the URL rewrite has been changed, make sure the category ID
         * will persist in the history state.
         */
        if (categoryIds !== prevCategoryIds) {
            this.updateHistory();
        }

        /**
         * If the currently loaded category ID does not match the ID of
         * category from URL rewrite, request category.
         */
        if ((categoryIds !== id) || (isSignedIn !== prevIsSignedIn)) {
            waitForPriorityLoad().then(
            /** @namespace Theme/Route/CategoryPage/Container/CategoryPageContainer/componentDidUpdate/waitForPriorityLoad/then */
                () => this.requestCategory(),
            );
        }

        /**
         * If category ID was changed => it is loaded => we need to
         * update category specific information, i.e. breadcrumbs.
         *
         * Or if the breadcrumbs were not yet updated after category request,
         * and the category ID expected to load was loaded, update data.
         */
        const categoryChange = id !== prevId
            || (!breadcrumbsWereUpdated && id === categoryIds)
            || currentPage !== prevPage;

        if (categoryChange) {
            this.checkIsActive();
            this.updateMeta();
            this.updateBreadcrumbs();
            this.updateHeaderState();
        }

        /*
        ** if category wasn't changed we still need to update meta for correct robots meta tag [#928](https://github.com/scandipwa/scandipwa-theme/issues/928)
        */
        if (!categoryChange
            && filter?.customFilters
            && prevFilter?.customFilters
            && Object.keys(filter.customFilters).length !== Object.keys(prevFilter.customFilters).length
        ) {
            this.updateMeta();
        }
    }

    onPageSizeChange(pageSize) {
        this.setState({ pageSize: Number(pageSize) });
    }

    onGridButtonClick() {
        const { defaultGridProductCount } = this.props;

        this.setState({ layout: 'grid', pageSize: defaultGridProductCount });
    }

    onListButtonClick() {
        const { defaultListProductCount } = this.props;

        this.setState({ layout: 'list', pageSize: defaultListProductCount });
    }

    containerProps() {
        return {
            ...super.containerProps(),
            layout: this.getLayout(),
            pageSize: this.getPageSize(),
        };
    }

    getLayout() {
        const { layout, defaultPlpType } = this.state;

        return layout ?? defaultPlpType;
    }

    getPageSize() {
        const { pageSize } = this.state;

        return pageSize ?? this.getDefaultPageSize();
    }

    getDefaultPageSize() {
        const { defaultListProductCount, defaultGridProductCount } = this.props;

        return this.getLayout() === 'list' ? defaultListProductCount : defaultGridProductCount;
    }

    /**
     * Product List Property types
     * Current Values: 'grid', 'list'
     */
    updatePlpTypes() {
        const { plpType, isMobile } = this.props;

        if (plpType.match('-')) {
            const plpTypes = plpType.split('-');
            const defaultType = isMobile ? 'grid' : plpTypes[0];

            this.setState({ defaultPlpType: defaultType, plpTypes });
        } else {
            const defaultType = isMobile ? 'grid' : plpType;
            this.setState({ defaultPlpType: defaultType, plpTypes: [plpType] });
        }
    }

    updateMeta() {
        const {
            updateMetaFromCategory,
            category,
            category: {
                canonical_url,
            } = {},
        } = this.props;

        const meta_robots = history.location?.search
            ? ''
            : 'follow, index';

        updateMetaFromCategory({
            ...category,
            meta_robots,
            canonical_url: this.getCanonicalWithPageNumber(canonical_url),
        });
    }

    getCanonicalWithPageNumber(canonical_url) {
        if (!canonical_url) {
            return null;
        }

        const pageNumber = getQueryParam('page', window.location);

        if (pageNumber) {
            return canonical_url.concat(`?page=${pageNumber}`);
        }

        return canonical_url;
    }
}

export default withReducers({
    CategoryReducer,
})(connect(mapStateToProps, mapDispatchToProps)(CategoryPageContainer));
