/* eslint-disable react/forbid-dom-props */
import {
    ProductListComponent as SourceProductList,
} from 'SourceComponent/ProductList/ProductList.component';

/** @namespace Theme/Component/ProductList/Component */
export class ProductListComponent extends SourceProductList {
    render() {
        const {
            totalPages,
            isLoading,
            mix,
        } = this.props;

        if (!isLoading && totalPages === 0) {
            return this.renderNoProducts();
        }

        return (
            <div
              block="ProductList"
              mods={ { isLoading } }
              mix={ mix }
            >
                { this.renderTitle() }
                { this.renderLoadButton() }
                <div className="Pagination-Top">
                    { this.renderPagination() }
                </div>
                { this.renderPages() }
                <div className="Pagination-Bottom">
                    { this.renderPagination() }
                </div>
            </div>
        );
    }
}

export default ProductListComponent;
